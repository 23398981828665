body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left:calc(100vw - 100%)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 日期选择器修改主题颜色 */
.ant-btn{
  background-color: #19A789!important;
  border-color: #19A789 !important;
}
/* .ant-picker-focused{
  border-color: #19A789 !important;
  box-shadow:none !important;
}

.ant-picker:hover, .ant-picker-focused{
  border-color: #19A789 !important;
}
.ant-picker-range .ant-picker-active-bar{
  background: #19A789 !important;
}
.ant-picker{
  border: 1px solid #19A789 !important;
} */