.box{
    width: 500px;
    height: 620px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction:column;
    padding-right: 58px;
    padding-left: 58px;
    padding-top: 50px;
}
.codeImg{
    width: 120px;
    height: 40px;
  }

.bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
.bg2{
    width: 100%;
}
.box .inputs{
    width: 228px;
    height: 40px;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
}
.codeInputs{
    height: 40px;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
}