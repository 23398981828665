.btnbox{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 50px;
    margin-top: 50px;
}

.datePickerBox{
    width: 100%;
    height: 200px;
    width:"100%",
}