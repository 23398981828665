
.warp{
    max-width:1750px;
    margin: 0 auto;
}
.headBox{
    display: flex;
    justify-content: space-between;
}
.user{
    display: flex;
}
.headBox .info{
    display: flex;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}
.divider{
    height: 32px !important;
}
.headBox .info .title3{
    color: #19A789;
    font-size: 24px;
}
.headBox .info .title4{
    font-size: 24px;
}
.tableTitle{
    font-size: 20px;
    margin: 20px 0;
}
@media screen and (max-width:1770px ){
    .warp{
        width:100%;
        padding-bottom: 50px;
        padding-left:20px;
        padding-right:20px;
    }
    
    
}