:global .ant-page-header-heading {
  display: block;
}
.productPic {
  width: 100px;
  height: 60px;
}
.option {
  color: #028f3b;
  cursor: pointer;
}
.btnlist {
  display: flex;
}
.warp{
  width:1750px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.proTable{
  margin-top: 20px;
}
:global .ant-card-body {
  padding: 0!important;
}

:global .ant-pro-table-search{
  padding-top: 20px;
  margin-bottom: 20px;
}


@media screen and (max-width:1770px ){
  .warp{
      width:100%;
      padding-bottom: 50px;
      padding-left:20px;
      padding-right:20px;
  }

  
}
