
.header{
    width: 100%;
    background: #FFFFFF;
    height: 80px;
    box-shadow: 0px 15px 20px rgba(198, 203, 212, 0.25);
}
.warp{
    width:1750px;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.logo{
    width: 160px;
    margin-top: 20px;
}
.icons .icon{
    width: 22px;
    height: 22px;
    cursor: pointer;
}
.icons .icon:nth-child(1){
    margin-right: 20px;
}
.navbox{
    width: 100%;
    margin-top: 30px;
    /* padding-bottom: 20px; */
    
    
}
.navbox .list{
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #E4E7ED;
    height: 30px;
    position: relative;
}
.navbox .list .item{
    color: #19A789;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    line-height: 24px;
    margin-right: 50px;
    cursor: pointer;
    transition: 0.3s;
}
.navbox .list .none{
    color: #303133;
    font-size: 18px;
    position: relative;
    line-height: 24px;
    margin-right: 50px;
    cursor: pointer;

}
.navbox .list .nmr{
    margin-right: 0px !important;
}
.navbox .list .item .line{
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #8FF379 0%, #1CBDB4 100%);
    position: absolute;
    bottom: -2px;
    left: 0;
    opacity: 1;
    transition: 0.3s;
}
.navbox .list .item .nline{
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    opacity: 0;
}
@media screen and (max-width:1770px ){
    .warp{
        width:100%;
        padding-bottom: 30px;
        padding-left:20px;
        padding-right:20px;
    }
}
