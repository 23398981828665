.warp {
  max-width: 1750px;
  margin: 0 auto;
}

@media screen and (max-width: 1770px) {
  .warp {
    width: 100%;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
