.warp {
  width: 1750px;
  margin: 0 auto;
  padding-bottom: 50px;
}

/* 顶部数据看板 */
.board {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.board .box1 {
  width: 446px;
  height: 450px;
  background: linear-gradient(272.54deg, #8ff379 -53.22%, #1cbdb4 98.1%);
  box-shadow: 0px 15px 20px rgba(198, 203, 212, 0.25);
  border-radius: 10px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.tdsj {
  width: 100%;
  flex: 1;
}
.board .box2 {
  width: 831px;
  height: 450px;
  background: linear-gradient(90deg, #887bf2 0%, #bb9bf1 100%);
  box-shadow: 0px 15px 20px rgba(198, 203, 212, 0.25);
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.yhsj {
  width: 100%;
  flex: 1;
}
.box2 .labels {
  position: absolute;
  left: 20px;
  top: 50%;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
}
.box2 .labels .name1::before {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  background-color: #aaed94;
  border-radius: 50%;
  margin-right: 6px;
  margin-top: 2px;
}
.box2 .labels .name2::before {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  background-color: #37d6ef;
  border-radius: 50%;
  margin-right: 6px;
  margin-top: 2px;
}
.box2 .labels .name2 {
  margin-top: 10px;
}

.board .box3 {
  width: 387px;
  height: 450px;
  background: linear-gradient(89.29deg, #3580fb -60.9%, #8bc0fb 99.41%);
  border-radius: 10px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.allbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.all {
  width: 14px;
  height: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #fff;
}
.all div {
  width: 8px;
  height: 8px;
  background: linear-gradient(
    88.98deg,
    #19a789 0.87%,
    rgba(12, 183, 150, 0.489583) 142.94%,
    rgba(0, 198, 162, 0) 239.18%
  );
  border-radius: 50%;
}
.lqsj {
  width: 100%;
  flex: 1;
}

.board .title {
  font-weight: 600;
  font-size: 28px;
  color: #fff;
}
.board .countBox {
  font-weight: 900;
  font-size: 18px;
  color: #d4fc79;
  margin-top: 10px;
}

.date {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.date img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
/* 地图模块 */
.mapBox {
  background-color: #f5f7fa;
  height: 612px;
  width: 100%;
  margin-top: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.mapBox .left {
  width: 500px;
  background-color: E5E5E5;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.dttdsj {
  width: 100%;
  flex: 1;
}
.mapBox .left .title {
  font-size: 34px;
  color: #1b1b1b;
  margin-top: 80px;
}
.mapBox .left .date {
  color: #333;
}
.mapBox .left .allbtn {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.mapBox .left .point {
  background: linear-gradient(
    88.98deg,
    #19a789 0.87%,
    rgba(12, 183, 150, 0.489583) 142.94%,
    rgba(0, 198, 162, 0) 239.18%
  );
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.ulMap {
  flex: 1;
  /* background-color: pink; */
}
/* 投递数据 */

.deliveryBox {
  height: 500px;
  display: flex;
  margin-top: 80px;
  justify-content: space-between;
}
.deliveryBox .left {
  width: 1064px;
  height: 100%;
}
.deliveryBox .left .titleBox {
  display: flex;
  color: #151515;
  font-size: 34px;
  align-items: center;
  padding-left: 30px;
}
.deliveryBox .titleBox .more {
  font-size: 18px;
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.deliveryBox .tablebox {
  margin-top: 10px;
}
.deliveryBox .right {
  width: 584px;
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;
  height: 100%;
  border-radius: 10px;
}
.deliveryBox .right .head {
  padding: 10px 40px;
}
.deliveryBox .right .head .title {
  margin-top: 0px;
}
.deliveryBox .right .title {
  font-size: 34px;
  color: #1b1b1b;
  margin-top: 80px;
}
.deliveryBox .right .date {
  color: #333;
}
.deliveryBox .right .allbtn {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.deliveryBox .right .point {
  background: linear-gradient(
    88.98deg,
    #19a789 0.87%,
    rgba(12, 183, 150, 0.489583) 142.94%,
    rgba(0, 198, 162, 0) 239.18%
  );
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}
.deliveryBox .tdjg {
  flex: 1;
  height: 100%;
}
.deliveryBox .tdjgBox {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.deliveryBox .tdjgBox .bfb {
  display: flex;
  width: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0a0a09;
  font-size: 24px;
}

.deliveryBox .bfb .count_l {
  background: linear-gradient(to right, rgb(122, 141, 156), rgb(176, 188, 196));
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 28px;
  font-weight: bold;
}
.deliveryBox .bfb .count_r {
  background: linear-gradient(to right, rgb(23, 167, 136), rgb(61, 190, 165));
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 28px;
  font-weight: bold;
}
.allMap {
  width: 120px;
  height: 40px;
  background: #19a789;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
}
.allMap:hover {
  color: #3580fb;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  /* width: 182px; */
  height: 40px;
  /* background-color: #fff; */
  border-radius: 5px;
}
.switch div {
  cursor: pointer;
  width: 76px;
  height: 30px;
  color: #19a789;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
}
.switch .active {
  background-color: #19a789;
  color: #fff;
  transition: 0.3s;
}
@media screen and (max-width: 1770px) {
  .warp {
    width: 100%;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .board .box1 {
    width: 27%;
    height: 450px;
  }
  .board .box2 {
    width: 42%;
    height: 450px;
  }
  .board .box3 {
    width: 27%;
    height: 450px;
  }
  .mapBox .left {
    width: 400px;
    background-color: E5E5E5;
    padding-left: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .deliveryBox .right {
    width: 484px;
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    height: 100%;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1550px) {
  .mapBox .left {
    width: 300px;
    background-color: #e5e5e5;
    padding-left: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  /* 投递 */

  .deliveryBox .left {
    width: 843px;
    height: 100%;
  }
  .deliveryBox .left .titleBox {
    display: flex;
    color: #151515;
    font-size: 34px;
    align-items: center;
    padding-left: 30px;
  }
  .deliveryBox .titleBox .more {
    font-size: 18px;
    flex: 1;
    text-align: center;
  }
  .deliveryBox .tablebox {
    margin-top: 10px;
  }
  .deliveryBox .right {
    width: 457px;
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    height: 100%;
    border-radius: 10px;
  }
  .deliveryBox .tdjgBox .bfb {
    display: flex;
    width: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0a0a09;
    font-size: 18px;
  }
  .deliveryBox .bfb .count_l {
    font-size: 22px;
  }
  .deliveryBox .bfb .count_r {
    font-size: 22px;
  }
}
